<template>
  <v-card flat class="my-6">
    <v-card outlined class="d-flex align-center justify-center mt-6">
      <v-card flat tile width="100%">
        <v-card-title
          class="text-subtitle-1 lightgray px-3 py-1 justify-center"
        >
          Total
          <v-spacer></v-spacer>
          <div class="text-h6 mr-4">
            <span v-if="ordem_servico.moeda_sigla === 'G$'">
              {{ ordem_servico.moeda_sigla }} {{ ordem_servico.valor | guarani }}
            </span>
            <span v-else>
              {{ ordem_servico.moeda_sigla }} {{ ordem_servico.valor | currency }}
            </span>
          </div>
        </v-card-title>
      </v-card>
      <v-divider vertical></v-divider>
      <v-card flat tile width="100%">
        <v-card-title
          class="text-subtitle-1 lightgray px-3 py-1 justify-center"
        >
          Restante
          <v-spacer></v-spacer>
          <div class="text-h6 mr-4">
            <span v-if="ordem_servico.moeda_sigla === 'G$'">
              {{ ordem_servico.moeda_sigla }} {{ totalRestante | guarani }}
            </span>
            <span v-else>
              {{ ordem_servico.moeda_sigla }} {{ totalRestante | currency }}
            </span>
          </div>
        </v-card-title>
      </v-card>
      <v-divider vertical></v-divider>
      <v-card flat tile width="100%">
        <v-card-title
          class="text-subtitle-1 lightgray px-3 py-1 justify-center"
        >
          Recebido
          <v-spacer></v-spacer>
          <div class="text-h6 mr-4">
            <span v-if="ordem_servico.moeda_sigla === 'G$'">
              {{ ordem_servico.moeda_sigla }} {{ totalPagamentos | guarani }}
            </span>
            <span v-else>
              {{ ordem_servico.moeda_sigla }} {{ totalPagamentos | currency }}
            </span>
          </div>
        </v-card-title>
      </v-card>
    </v-card>
    <BaixarVendasAddFormaPago
      @push-pagamento="pushPagamentoItens"
      :valor-restante="totalRestante"
      :moeda_conta="ordem_servico.moeda_sigla"
      :cliente_id="ordem_servico.cliente_id"
    />

    <v-card class="my-6" outlined>
      <v-card-title class="text-subtitle-1 px-3 py-2 lightgray">
        Formas de Pagamento
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headerFormaPagamentoItems"
          :items="formaPagamentoItems"
          :items-per-page="5"
          disable-sort
          class="data-tables data-tables__row-click"
          :hide-default-footer="formaPagamentoItems.length > 5 ? false : true"
        >
          <template v-slot:item.valor="{ item }">
            {{ item.moeda }}
            <span v-if="item.moeda === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.valor | currency }}
            </span>
          </template>

          <template v-slot:item.consolidado="{ item, index }">
            <div v-if="!editConsolidado || indexEditConsolidado != index">
              {{ ordem_servico.moeda_sigla }}
              <span v-if="ordem_servico.moeda_sigla === 'G$'">
                {{ item.consolidado | guarani }}
              </span>
              <span v-else>
                {{ item.consolidado | currency }}
              </span>
              <v-btn
                @click="editarConsolidado(item.consolidado, index)"
                icon
                x-small
              >
                <v-icon> mdi-pencil-outline </v-icon>
              </v-btn>
            </div>

            <v-text-field
              v-show="editConsolidado && indexEditConsolidado === index"
              v-model="consolidadoEdicao"
              label="Consolidado"
              ref="consolidadoEdicao"
              outlined
              dense
              hide-details
              style="width: 250px"
              class="input-right my-1 ml-4"
              v-currency="vCurrencyOptions"
            >
              <template v-slot:append>
                <v-btn
                  v-show="editConsolidado && indexEditConsolidado === index"
                  @click="salvaConsolidado(index)"
                  icon
                  x-small
                >
                  <v-icon> mdi-check </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </template>

          <template v-slot:item.actions="{ index }">
            <v-btn icon @click="removeFormaPagamento(index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BaixaOs",
  props: {
    ordem_servico: {
      type: Object,
    },
  },

  components: {
    BaixarVendasAddFormaPago: () => import("./BaixarVendasAddFormaPago.vue"),
  },

  data() {
    return {
      loading: false,

      formaPagamentoItems: [],
      editConsolidado: false,
      consolidadoEdicao: null,
      indexEditConsolidado: null,
    };
  },

  computed: {
    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),
    headerFormaPagamentoItems() {
      return [
        {
          text: "Formas de Pagamento",
          value: "forma_pagamento",
        },
        {
          text: "Destino",
          value: "destino",
        },
        {
          text: "Valor",
          align: "right",
          value: "valor",
        },
        {
          text: "Consolidado",
          align: "right",
          width: "250px",
          value: "consolidado",
        },
        {
          text: "",
          align: "center",
          value: "actions",
        },
      ];
    },

    totalPagamentos() {
      let total = this.formaPagamentoItems.reduce((accumulator, item) => {
        return accumulator + item.consolidado;
      }, 0);

      return total;
    },

    totalRestante() {
      let total = Number(this.ordem_servico.valor) - this.totalPagamentos;
      if (total > 0) {
        return total;
      } else {
        return 0;
      }
    },

    vCurrencyOptions() {
      return {
        precision: undefined,
        distractionFree: false,
      };
    },
  },

  methods: {
    pushPagamentoItens(item) {
      this.formaPagamentoItems.push(item);
      this.$emit("update:formas-pagamentos", this.formaPagamentoItems);
      this.$emit("update:valor-restante", this.totalRestante);
    },

    removeFormaPagamento(index) {
      this.formaPagamentoItems.splice(index, 1);
      this.$emit("update:formas-pagamentos", this.formaPagamentoItems);
      this.$emit("update:valor-restante", this.totalRestante);
    },

    editarConsolidado(item, index) {
      this.indexEditConsolidado = index;

      this.$ci.setValue(
        this.$refs.consolidadoEdicao,
        Number(this.formaPagamentoItems[this.indexEditConsolidado].consolidado)
      );

      this.editConsolidado = true;
    },

    salvaConsolidado(index) {
      this.formaPagamentoItems[index].consolidado = this.$ci.parse(
        this.consolidadoEdicao,
        this.vCurrencyOptions
      );
      this.indexEditConsolidado = null;
      this.consolidadoEdicao = null;
      this.editConsolidado = false;
      this.$emit("update:valor-restante", this.totalRestante);
    },
  },

  mounted() {
    this.formaPagamentoItems = [];
    let pagamento = {};
    pagamento.cambio = 1;
    pagamento.consolidado = this.totalRestante;
    pagamento.destino = this.caixa_af.caixa_descricao;
    pagamento.destino_id = this.caixa_af.id;
    pagamento.forma_de_pagamento_id = 1;
    pagamento.forma_pagamento = "DINHEIRO";
    pagamento.moeda = "G$";
    pagamento.valor = this.totalRestante;
    this.formaPagamentoItems.push(pagamento);
    this.$emit("update:formas-pagamentos", this.formaPagamentoItems);
    this.$emit("update:valor-restante", this.totalRestante);
  },
};
</script>

<style></style>
